import { differenceInMilliseconds, startOfToday, addMilliseconds } from "date-fns";
import { format } from "date-fns/esm";
import { getTZPrefix } from "./globals";

export const DATEDIFF = "(datediff)", IFNULL = "(ifnull)", FORMAT = "(dtformat)", JOINSTR = "(joinstr)",
    EQUALS = "(eq)", NOTEQUALS = "(neq)", IFTRUE = "(iftrue)", IFFALSE = "(iffalse)", BOOL_NOT = "(not)",
    TOSTRING = "(tostr)", STRAFTER = "(strafter)",
    MATH_ADD = "(+)", MATH_SUB = "(-)", MATH_MULTI = "(*)", MATH_DIV = "(/)", MATH_MOD = "(%)", MATH_POW = "(^)",
    IF_NAN = "(ifnan)", NUM_FORMAT = "(numformat)", LIST_REVERSE = "(listreverse)", LIST_SUM = "(listsum)", LIST_COUNT = "(listcount)",LIST_INDEX = "(listindex)",
    OBJ_TYPE = "(type)", OBJ_GET = "(get)";
export const funcs = [DATEDIFF, IFNULL, FORMAT, JOINSTR, EQUALS, NOTEQUALS, IFTRUE, IFFALSE, BOOL_NOT, TOSTRING, STRAFTER,
    MATH_ADD, MATH_SUB, MATH_MULTI, MATH_DIV, MATH_MOD, MATH_POW, IF_NAN, NUM_FORMAT,LIST_REVERSE, LIST_COUNT, LIST_SUM, LIST_INDEX, OBJ_TYPE, OBJ_GET];

export function dyadicFuncExecutor(funcName, firstValue, secondValue) {
    let firstValMath = 0;
    let secondValMath = 0;
    if ([MATH_ADD, MATH_SUB, MATH_MULTI, MATH_DIV, MATH_MOD, MATH_POW].includes(funcName)) {
        firstValMath = parseFloat(firstValue);
        secondValMath = parseFloat(secondValue);
        if (isNaN(firstValMath)) firstValMath = 0;
        if (isNaN(secondValMath)) secondValMath = 0;
    }
    switch (funcName) {

        case DATEDIFF:
            return _dateDiffExec(firstValue, secondValue);
        case IFNULL:
            return _ifNullExec(firstValue, secondValue);
        case FORMAT:
            return _formatDateTime(firstValue, secondValue);
        case JOINSTR:
            return (firstValue ?? "") + (secondValue ?? "");
        case STRAFTER:
            return firstValue.substr(firstValue.indexOf(secondValue) + 1);
        case EQUALS:
            return secondValue.split("||").some(el => el == firstValue); //No type checking
        case NOTEQUALS:
            return secondValue.split("||").every(el => el != firstValue); //No type checking
        case TOSTRING:
            return JSON.stringify(firstValue);
        case MATH_ADD:
            return firstValMath + secondValMath;
        case MATH_SUB:
            return firstValMath - secondValMath;
        case MATH_MULTI:
            return firstValMath * secondValMath;
        case MATH_DIV:
            return firstValMath / secondValMath;
        case MATH_MOD:
            return firstValMath % secondValMath;
        case MATH_POW:
            return Math.pow(firstValMath, secondValMath);
        case IF_NAN:
            if (isNaN(firstValue)) return secondValue;
            else return firstValue;
        case NUM_FORMAT:
            let toFix = parseInt(secondValue);
            let valNumber = parseFloat(firstValue);
            if (isNaN(valNumber)) return firstValue;
            if (isNaN(toFix)) toFix = 2;
            return valNumber.toFixed(toFix);
        case LIST_SUM:
            return calculateListSummary(firstValue, secondValue);
        case LIST_REVERSE:
            if(Array.isArray(firstValue)){
                return firstValue?.slice()?.reverse(); 
            }
            return firstValue;
        case LIST_COUNT:
            return calculateListCount(firstValue);
        case LIST_INDEX:
            return getListIndexMatchingValue(firstValue, secondValue);
        case BOOL_NOT:
            return !firstValue;
        case OBJ_TYPE:
            if (firstValue === null || typeof firstValue === "undefined") return "null";
            let valType = typeof firstValue;
            if (valType === "object" && Array.isArray(firstValue)) valType = "array";
            return valType;
        case OBJ_GET:
            if (typeof firstValue === "object") {
                return firstValue[secondValue];
            }
            return firstValue;
        default:
            return null;
    }
}
function calculateListSummary(dsetArray, fieldName) {
    let total = 0;
    if (Array.isArray(dsetArray)) {
        for (let di of dsetArray) {
            let currentItem = di[fieldName];
            if (typeof currentItem !== 'undefined') {
                let ciValue = parseFloat(currentItem);
                if (!isNaN(ciValue)) {
                    total += ciValue;
                }
            }
        }
    }
    return total;
}

function getListIndexMatchingValue(dsetArray, fieldNameAndValue) {
     if (Array.isArray(dsetArray)) {
        let fieldArray =  fieldNameAndValue.split("||"); 
        return dsetArray.findIndex(t=> {
            let assertedMatches = [];
            for(let fa of fieldArray){ 
                let keyfa = fa.split("=>"); 
                assertedMatches.push(t[keyfa[0]] == keyfa[1]); 
            }
            return assertedMatches.every(t=> t=== true); 
        }); 
       
    }
    return -1; 
 }
function calculateListCount(dsetArray) {
    let count = 0;
    if (Array.isArray(dsetArray)) {
        count = dsetArray.length;
    }
    return count;
}
function _dateDiffExec(firstValue, secondValue) {
    if (typeof firstValue === "string") firstValue += getTZPrefix();
    if (typeof secondValue === "string") secondValue += getTZPrefix();

    let firstDateValue = Date.parse(firstValue),
        secondDateValue = Date.parse(secondValue);
    if (!firstDateValue) firstDateValue = new Date();
    if (!secondDateValue) secondDateValue = new Date();
    let millis = differenceInMilliseconds(secondDateValue, firstDateValue);
    return addMilliseconds(startOfToday(), millis);
}

const _ifNullExec = (firstValue, secondValue) => firstValue ?? secondValue;  //Return second value if first value is null



function _formatDateTime(firstValue, formatString) {
    try {
        if (typeof firstValue === "string") firstValue += getTZPrefix();
        let dateValue = Date.parse(firstValue);
        console.log(firstValue)
        console.log(dateValue)
        if (!dateValue) return null; // dateValue = new Date();
        if (!formatString) formatString = "dd-MMM-yyyy";
        return format(dateValue, formatString);
    }
    catch {
        return firstValue;
    }
}