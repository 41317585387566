import HttpService from "./httpService";
import { procPath, reportPath,  uploadPath, apiRoot, uploadRoot } from "../General/globals";

class ServiceBase {

  async loadFromFileURL(fileURL) {
    let httpSvc = new HttpService();

    let json = await httpSvc.downloadFile(fileURL);
    return json;
  }

  async loadFromServer(job_id, procKey, accessToken, dataToSend, resultType) {
    let httpSvc = new HttpService();
    let headers = httpSvc.getCommonHeaders(accessToken);
    let requestBody = httpSvc.getProcPostBodyString(job_id, procKey, dataToSend, resultType);
    let endPoint = procPath ; 
    if(resultType === "report") endPoint = reportPath; //using different endpoint api/report for downloading report data
    let json = await httpSvc.postServer(apiRoot, endPoint, headers, requestBody);
    return json;
  }

  async sendFileToServer(job_id, accessToken, dataToSend) {
    let httpSvc = new HttpService();
    let headers = httpSvc.getCommonHeaders(accessToken);
    let requestBody = httpSvc.getFilePostBodyString(job_id, dataToSend);

    let json = await httpSvc.postServer(uploadRoot, uploadPath, headers, requestBody);
    return json;
  }

}

export default ServiceBase; 