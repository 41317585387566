import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import NumberFormat from 'react-number-format';
import { dyadicFuncExecutor } from '../../../General/funcExecutor';
import { GetControlPropertyFromStoreOrRefData } from '../../../General/commonFunctions';
import store from '../../../AppRedux/store';




class FactsDataGrid extends Component {
  constructor(props) {
    super(props);
    let resultLayoutInfo = GetControlPropertyFromStoreOrRefData(this.props?.layout, this.props.refData);
    let resultDataInfo = GetControlPropertyFromStoreOrRefData(this.props?.data, this.props.refData);
    this.state = {
      layout: resultLayoutInfo,
      data: resultDataInfo
    }
  }

  async componentDidMount() {

    // let resultLayoutInfo = await pageServiceInstance.loadData(this.props?.layout?.proc, this.props?.layout?.args);

    // let resultDataInfo = await pageServiceInstance.loadData(this.props?.data?.proc, this.props?.data?.args);
    this.mounted = true;
    this.unsubscribe = store.subscribe(() => {
      if (this.mounted) {

        let resultLayoutInfo = GetControlPropertyFromStoreOrRefData(this.props?.layout, this.props.refData);
        let resultDataInfo = GetControlPropertyFromStoreOrRefData(this.props?.data, this.props.refData);
        this.setState({
          layout: resultLayoutInfo,
          data: resultDataInfo
        });


      }
    });
  }

  componentWillUnmount() {
    this.mounted = false;
    if (this.unsubscribe) this.unsubscribe();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(this.state) === JSON.stringify(nextState)) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    return (
      // <IntlProvider locale={navigator.language}>
      <TableContainer component={Paper} style={{ boxShadow: "none", ...this.props?.style }} >
        <Table size="small" stickyHeader >
          <TableHead className="fdatagrid-head">
            <TableRow>
              {
                this.state?.layout &&
                this.state.layout.map((t, index) => {
                  return <TableCell key={index}><strong>{t.dmldet_column_caption}</strong></TableCell>
                })
              }

            </TableRow>
          </TableHead>
          <TableBody>
            {
              this.state?.data &&
              this.state.data.map(
                (row, index) => {
                  return (
                    <TableRow key={index}>
                      {this.state.layout.map((col, colindex) => {
                        return <TableCell className={`tbcell ${col.dmldet_column_alignment}`} key={colindex}>
                          {this._getFormattedCellValue(row, col)}

                        </TableCell>
                      })}
                    </TableRow>
                  );
                }

              )
            }
          </TableBody>
        </Table>
      </TableContainer>
      // </IntlProvider>
    );
  }

  _getFormattedCellValue(row, col) {
    if (col.dmldet_column_type === "datetime") {
      return (
        // <FormattedDate format={} value={} />
        <span>{dyadicFuncExecutor("(dtformat)", row[col.dmldet_column_name], this._getDateFormat(col.dmldet_column_format))}</span>
      );
    }
    if (col.dmldet_column_type === "number") {
      return (
        <NumberFormat displayType="text" value={row[col.dmldet_column_name]} />

      );
    }
    else {
      if(typeof col.DMLDET_MORE_OPTIONS !== "undefined"){
        try{
          let domparser = new DOMParser();
          let xmlObject = domparser.parseFromString(col.DMLDET_MORE_OPTIONS , 'text/xml');
          let cTypeEl =  xmlObject.getElementsByTagName("ControlType"); 
          if(cTypeEl.length > 0){
            let cNodes = cTypeEl[0]; 
            if(cNodes.childNodes.length > 0) {
              let cType = cNodes.childNodes[0].nodeValue; 
              if(cType === "RepositoryItemHyperLinkEdit"){
                let cValue = row[col.dmldet_column_name]; 
                return (<a href={`${cValue}`} title={`${cValue}`} target="_blank" rel="noopener noreferrer">View</a>);

              }
            }
          }

        }
        catch{}
       
      }
      return (row[col.dmldet_column_name]);
    }
  }

  _getDateFormat(dateFormat) {
    if (dateFormat) {
      return dateFormat;
    }
    return 'DD/MM/YYYY';
  }

  _getNumberFormat(numberFormat) {
    if (numberFormat) {
      return numberFormat;
    }
    return '';
  }

}

export default FactsDataGrid; 