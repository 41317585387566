import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import React, { Component } from "react";
import { ExecuteLayoutEventMethods, GetControlPropertyFromStoreOrRefData } from "../../../General/commonFunctions";
import store from "../../../AppRedux/store";
import { IconButton } from "@material-ui/core";

class FactsLookup extends Component {
    constructor(props) {
        super(props);
        this.rowIndex = GetControlPropertyFromStoreOrRefData(props.rowindex, props.refData) ?? "";

        let textValueFromState = GetControlPropertyFromStoreOrRefData("[" + props.dset + "." + this.rowIndex + "." + props.bind + "]", this.props.refData);
        this.state = {
            textValue: (textValueFromState === null ? "" : textValueFromState)
        }
    }

    ripOffControlSpecificAttributes() {
        const excluded = ["className", "includerefdata", "refData"];
        return (
            Object.keys(this.props)
                .filter((t) => !excluded.includes(t))
                .reduce((obj, key) => {
                    obj[key] = this.props[key];
                    return obj;
                }, {}));

    }

    componentDidMount() {
        this.unsubscribe = store.subscribe(() => {

            let newState = GetControlPropertyFromStoreOrRefData("[" + this.props.dset + "." + this.rowIndex + "." + this.props.bind + "]", this.props.refData);

            if (this.state.textValue !== newState) {
                this.setState({
                    textValue: newState
                });
            }
        });

    }
    componentWillUnmount() {
        if (this.unsubscribe) this.unsubscribe();
    }

    async handleOnClick(e) {
        await ExecuteLayoutEventMethods(this.props.whenclick, this.props.refData);
    }

    async handleEmptyIconOnClick(e) {
        e.stopPropagation(); 
        if(!this.props.icons?.empty?.whenclick) await this.handleOnClick(e); 
        else await ExecuteLayoutEventMethods(this.props.icons?.empty?.whenclick, this.props.refData);
      
    }

    async handleNotEmptyIconOnClick(e) {
        e.stopPropagation(); 
        if(!this.props.icons?.notempty?.whenclick) await this.handleOnClick(e); 
         else await ExecuteLayoutEventMethods(this.props.icons?.notempty?.whenclick, this.props.refData);
      
    }


    // shouldComponentUpdate(nextProps, nextState) {
    //     if (JSON.stringify(this.state) === JSON.stringify(nextState)) {
    //         return false;
    //     } else {
    //         return true;
    //     }
    // }

    render() {
        let newProps = this.ripOffControlSpecificAttributes();
        let adornIcon = null;
        if (this.props.icons) {
            if ((this.state.textValue ?? "") === "") {
                adornIcon = (<IconButton onClick={(e) => this.handleEmptyIconOnClick(e) }
                    onMouseDown={(e) => e.preventDefault()}>
                    <Icon>{this.props.icons?.empty?.icon ?? this.props.icon}</Icon>
                </IconButton>);
            }
            else {
                adornIcon = (<IconButton onClick={(e) => this.handleNotEmptyIconOnClick(e)}
                    onMouseDown={(e) => e.preventDefault()}>
                    <Icon>{this.props.icons?.notempty?.icon ?? this.props.icon}</Icon>
                </IconButton>);
            }
        }
        else adornIcon = <Icon>{this.props.icon}</Icon>;

        return (
            <TextField
                className={"facts-lookup " + (this.props.className ?? "")}
                InputProps={{
                    readOnly: true,
                    endAdornment: <InputAdornment position="end">{adornIcon}</InputAdornment>
                }}
                variant="outlined" size="small" {...newProps} value={this.state.textValue ?? ""}
                // onChange={(e) => this.handleOnChange(e)} onBlur={() => this.handleOnBlur()}
                onClick={(e) => this.handleOnClick(e)} />
        )
    }
}

export default FactsLookup;