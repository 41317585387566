import TextField from "@material-ui/core/TextField";
import React, { Component } from "react";
import { ExecuteLayoutEventMethods, GetControlPropertyFromStoreOrRefData } from "../../../General/commonFunctions";
import store from "../../../AppRedux/store";
import { InputBase } from "@material-ui/core";
import { CollectionsBookmarkOutlined } from "@material-ui/icons";

class FactsEntry extends Component {
    
    constructor(props) {
        super(props);
        this.rowIndex = GetControlPropertyFromStoreOrRefData(props.rowindex, props.refData) ?? "";

        let textValueFromState = GetControlPropertyFromStoreOrRefData("[" + props.dset + "." +
            this.rowIndex + "." + props.bind + "]", props.refData);
        this.state = {
            textValue: (textValueFromState === null ? "" : textValueFromState)
        }
        this.finalValue = (textValueFromState === null ? "" : textValueFromState);
        this.isChangeDirty = false; 
        this.isBlurDirty = false; 
    }

    componentDidMount() {
        this.mounted = true;
        this.unsubscribe = store.subscribe(() => {
            if (this.mounted && (!this.isChangeDirty) &&  (!this.isBlurDirty)) {
                 if (this.props.dset && this.props.bind) {
                    let newState = GetControlPropertyFromStoreOrRefData("[" + this.props.dset + "." + this.rowIndex + "." + this.props.bind + "]", this.props.refData);
 
                    if (this.finalValue !== newState) {

                        if (newState === null) newState = "";
                        this.finalValue = newState;
 
                        this.setState({
                            textValue: newState
                        });

                    }
                }
            }
        });

    }



    componentWillUnmount() {
        this.mounted = false;
        if (this.unsubscribe) this.unsubscribe();

    }

    bindingEscapeValue(val){
        // if(val.startsWith("[") && val.endsWith("]")) return " " +  val + " ";
        // else 
        return val; 
    }
    async handleOnBlur(e) {
        let currentValue =this.bindingEscapeValue(this.state.textValue);
         if (this.props.type === "number") {
            currentValue = parseFloat(currentValue);
            if (this.props.inputProps?.min) {
                let minValue = parseFloat(this.props.inputProps?.min);

                if (currentValue < minValue) currentValue = minValue;
            }
            if (this.props.inputProps?.max) {
                let maxValue = parseFloat(this.props.inputProps?.max);
                if (currentValue > maxValue) currentValue = maxValue;
            }
        }
        this.finalValue = currentValue ?? "";
 

        this.setState({
            textValue: currentValue ?? ""
        }, async () => {
            this.isBlurDirty = true; 
            await this.saveCurrentTextToDataset();
     
            if(this.props.whenblur) await ExecuteLayoutEventMethods(this.props.whenblur, this.state);
            this.isBlurDirty = false; 

        });

    }
    async saveCurrentTextToDataset() {

        this.finalValue = this.state.textValue;
        
        if (this.props.dset && this.props.bind) {
            if (this.rowIndex !== "") {
                let rowData = GetControlPropertyFromStoreOrRefData("[" + this.props.dset + "." + this.rowIndex + "]", this.props.refData);

                await ExecuteLayoutEventMethods([
                    {
                        exec: "mergedatasetarray",
                        args: {
                            dset: this.props.dset,
                            index: parseInt(this.rowIndex),
                            data:
                            {
                                ...rowData,
                                [this.props.bind]: this.state.textValue
                            }
                        }
                    }]);
            }
            else {
                await ExecuteLayoutEventMethods([

                    {
                        exec: "setdatasetfield",
                        args: {
                            dset: this.props.dset,
                            fieldname: this.props.bind,
                            data: this.state.textValue
                        }
                    }
                ]);
            }
        }
    }
    async runCustomOnChangeEvents(eventToRun) {
        this.isChangeDirty = true; 
        clearTimeout(this.changeTO); 
        this.changeTO = setTimeout(async ()=> {
            await this.saveCurrentTextToDataset();

            if (eventToRun) await ExecuteLayoutEventMethods(eventToRun, this.state);
            this.isChangeDirty = false; 

        }, 500);

    }
    async handleOnChange(e) {
  
        this.finalValue = this.bindingEscapeValue(e.target.value ?? "");
        this.setState({
            textValue: e.target.value ?? ""
        }, () => {

            this.runCustomOnChangeEvents(this.props.whenchange);
        });
    }

    handleKeyPress(e) {
         if (e.charCode === 0) e.preventDefault();
    }
    async handleKeyUp(e) {
         if (typeof this.props.eventkeys === "undefined" || this.props.eventkeys.includes(e.keyCode)) {
            if (this.props.whenkeyup) await ExecuteLayoutEventMethods(this.props.whenkeyup, this.state);
        }
    }
    handleOnFocus(e) {
         e.preventDefault();
        const { target } = e;

        target.select();
    }
    // shouldComponentUpdate(nextProps, nextState) {
    //     if (JSON.stringify(this.state) === JSON.stringify(nextState)) {
    //         return false;
    //     } else {
    //         return true;
    //     }
    // }

    ripOffControlSpecificAttributes() {
        const excluded = ["resolveprops", "format", "refData", "rowindex"];
        return (
            Object.keys(this.props)
                .filter((t) => !excluded.includes(t))
                .reduce((obj, key) => {
                    obj[key] = this.props[key];
                    return obj;
                }, {}));

    }
    format(val) {
        if (this.props.type === "number" && this.props.format) {
            let decimals = parseInt(this.props.format);
            if (isNaN(decimals)) decimals = 2;
            let formatted = parseFloat(val).toFixed(decimals);
            if (isNaN(formatted)) formatted = "";
            return formatted;
        }
        else return val;
    }
    render() {
        let newProps = this.ripOffControlSpecificAttributes();
        let formatted = this.format(this.state.textValue);
        return (
            newProps?.variant === "base" ?
                <InputBase {...newProps} value={formatted}
                    onKeyPress={(e) => this.handleKeyPress(e)}
                    onKeyUp={(e) => this.handleKeyUp(e)}

                    onChange={(e) => this.handleOnChange(e)}
                    onBlur={() => this.handleOnBlur()}
                    onFocus={(e) => this.handleOnFocus(e)}
                />
                :
                <TextField variant="outlined" size="small" {...newProps} value={formatted}
                    onKeyPress={(e) => this.handleKeyPress(e)}
                    onKeyUp={(e) => this.handleKeyUp(e)}

                    onChange={(e) => this.handleOnChange(e)}
                    onBlur={() => this.handleOnBlur()}
                    onFocus={(e) => this.handleOnFocus(e)}
                />
        )
    }
}

export default FactsEntry; 