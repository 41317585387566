import React, { Component } from "react";
import { GetControlPropertyFromStoreOrRefData, ExecuteLayoutEventMethods } from "../../../General/commonFunctions";
import store from "../../../AppRedux/store";

class FactsCanvas extends Component {
    constructor(props) {
        super(props);
        this.fileContentFromState = GetControlPropertyFromStoreOrRefData(props.src);
        this.drawCanvas(this.fileContentFromState);
        // let disp = "inline"
        // if (!fileContentFromState && fileContentFromState === "") disp = "none";
        // let disp = "none"
        // if (this.fileContentFromState && this.fileContentFromState !== "") disp = "inline";
        // this.state = {
        //     isVisible: disp
        // };

    }

    ripOffControlSpecificAttributes() {

        const excluded = ["src", "staticimage", "bind"];
        return (
            Object.keys(this.props)
                .filter((t) => !excluded.includes(t))
                .reduce((obj, key) => {
                    obj[key] = this.props[key];
                    return obj;
                }, {}));

    }

    componentDidMount() {
        this.mounted = true;
        if (!this.props.staticimage) {
            this.unsubscribe = store.subscribe(() => {

                if (this.mounted) {
                    let newState = GetControlPropertyFromStoreOrRefData(this.props.src);




                    // this.setState({
                    //     fileContent: newState,
                    //     showImage: disp
                    // });
                    this.drawCanvas(newState);
                    // let disp = "none"
                    // if (newState && newState !== "") disp = "inline";

                    // this.setState({
                    //     isVisible: disp
                    // });


                }
            });
        }

    }

    drawCanvas(newState) {
        var myCanvas = document.getElementById(this.props.id ?? 'factsAppCanvas');
        if (myCanvas) {
         
            if (newState !== this.fileContentFromState) {
                myCanvas.style.display = "none";

                this.fileContentFromState = newState;


                var img = new Image();

                img.onload = function () {

                    var ctx = myCanvas.getContext('2d');

                    let isImageProportional = this.props.autorotate ?  (img.height >= img.width) : true; 
                    myCanvas.width = img.width;//myCanvas.parentNode?.clientWidth;
                    myCanvas.height = myCanvas.width *
                        (isImageProportional ? (img.height / img.width)
                            : (img.width / img.height));

                    // ctx.translate(myCanvas.height / 2, myCanvas.width / 2); //let's translate
                    if (!isImageProportional) {
                        ctx.translate(myCanvas.width / 2, myCanvas.height / 2);
                        ctx.rotate((Math.PI / 180) * 90);
                        ctx.translate(-myCanvas.height / 2, -myCanvas.width / 2);
                    }
                    ctx.drawImage(img, 0, 0,
                        isImageProportional ? myCanvas.width : myCanvas.height,
                        isImageProportional ? myCanvas.height : myCanvas.width,
                    ); // Or at whatever offset you like
                    if (this.props.dset && this.props.bind) {
                        ExecuteLayoutEventMethods([

                            {
                                exec: "setdatasetfield",
                                args: {
                                    dset: this.props.dset,
                                    fieldname: this.props.bind,
                                    data: myCanvas.toDataURL(this.props.filetype, this.props.quality)
                                }
                            }
                        ]);
                    }
                    window.URL.revokeObjectURL(newState);
                    img = null;
                    myCanvas.style.display = "inline";
                }.bind(this);
                img.src = newState;

            }
            //test
        }



    }



    componentWillUnmount() {
        this.mounted = false;
        if (this.unsubscribe) this.unsubscribe();

    }


    render() {
        let newProps = this.ripOffControlSpecificAttributes();
        return (
            // <img src={this.state.fileContent} alt="Stateful Graphic" {...newProps} style={{ display: this.state.showImage, ...newProps?.style }} />
            <canvas id="factsAppCanvas"  {...newProps}  ></canvas>
        );
    }
}

export default FactsCanvas; 