import React, { Component } from "react";
import { ChangePageDataSetState } from "../../../General/commonFunctions";
import store from "../../../AppRedux/store";
import { ViewState } from '@devexpress/dx-react-scheduler';

import { Scheduler, Appointments, MonthView, Toolbar, DateNavigator, TodayButton, AppointmentTooltip } from '@devexpress/dx-react-scheduler-material-ui';

const FactsDevScheduler = (props) => {
    const getCurrentDataSet = () => {
        let listds = ChangePageDataSetState(props.datasets);
        return listds[props.datasets[0]];
    }

    const [currentDS, setCurrentDS] = React.useState(getCurrentDataSet());
    let mounted = false, unsubscribe = null;

    const ripOffControlSpecificAttributes = () => {
        const excluded = ["datasets", "refData"];
        return (Object.keys(props)
            .filter((t) => !excluded.includes(t))
            .reduce((obj, key) => {
                obj[key] = props[key];
                return obj;
            }, {}));
    }

  
    React.useEffect(() => {
        mounted = true;
        if (props.datasets && props.datasets.length > 0) {
            setCurrentDS(getCurrentDataSet());
            unsubscribe = store.subscribe(() => {
                if (mounted) {
                    let newDS = getCurrentDataSet();
                    if (JSON.stringify(newDS) !== JSON.stringify(currentDS)) {
                        setCurrentDS(newDS);
                    }
                }
            });
        }

        return () => {
            mounted = false;
            if (unsubscribe) unsubscribe();
        };

    });

    const Cell = (props)=>{
        return <MonthView.TimeTableCell onDoubleClick={()=>{ console.log('cell_click') }} {...props} />
     }

    let newProps = ripOffControlSpecificAttributes();
    let listArrayToRender = currentDS;
    let index = 0;
    return (
        <Scheduler data={listArrayToRender} {...newProps}>
            <ViewState currentDate={Date.now()} />
            <MonthView>
                    <Cell />
            </MonthView>
            <Appointments />
            <AppointmentTooltip showCloseButton />
            <Toolbar />
            <DateNavigator  />
            <TodayButton />
        </Scheduler>
    );

}

export default FactsDevScheduler;