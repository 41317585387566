import React from 'react';

import { ButtonGroup, Icon } from '@material-ui/core';

import FactsDataGrid from '../../Parts/ControlsERP/factsDataGrid';

import FactsExpansionPanel from '../../Parts/Controls/factsExpansionPanel';
import FactsSwipeList from '../../Parts/Controls/factsSwipeList';
import FactsButton from '../../Parts/Controls/factsButton';
import FactsEntry from '../../Parts/Controls/factsEntry';
import FactsFAB from '../../Parts/Controls/factsFAB';
import FactsDropDown from '../../Parts/Controls/factsDropDown';
import FactsVirtualList from '../../Parts/Controls/factsVirtualList';
import { GetControlPropertyFromStoreOrRefData, getProcessedArgs } from '../../../General/commonFunctions';
import FactsList from '../../Parts/Controls/factsList';

import FactsLabel from '../../Parts/Controls/factsLabel';
import FactsIconButton from '../../Parts/Controls/factsIconButton';
import FactsDatePicker from '../../Parts/Controls/factsDatePicker';
import FactsLookup from '../../Parts/Controls/factsLookup';
import FactsTimePicker from '../../Parts/Controls/factsTimePicker';
import FactsDevList from '../../Parts/Controls/factsDevList';
import FactsClickRecognizer from '../../Parts/Controls/factsClickRecognizer';
import FactsFileButton from '../../Parts/Controls/factsFileButton';
import FactsImage from '../../Parts/Controls/factsImage';
import FactsAvatar from '../../Parts/Controls/factsAvatar';
import FactsHidden from '../../Parts/Controls/factsHidden';
// import FactsPDFPreview from '../../Parts/Controls/factsPDFPreview';
import FactsERPChart from '../../Parts/ControlsERP/factsERPChart';
import FactsCanvas from '../../Parts/Controls/factsCanvas';
import FactsExtraContent from '../../Parts/Controls/factsExtraContent';
import FactsRenderer from '../../Parts/Controls/factsRenderer';
import FactsCheckbox from '../../Parts/Controls/factsCheckbox';
import FactsFABMulti from '../../Parts/Controls/factsFABMulti';
import FactsSection from '../../Parts/Controls/factsSection';
import FactsTreeview from '../../Parts/Controls/factsTreeview';
import FactsQRCode from '../../Parts/Controls/factsQRCode';
import FactsBarCode from '../../Parts/Controls/factsBarCode';
import FactsDevScheduler from '../../Parts/Controls/factsDevScheduler';
import FactsHTMLView from '../../Parts/Controls/factsHTMLView';
import FactsDraw from '../../Parts/Controls/factsDraw';

// import { Suspense } from 'react';
// const FactsList = React.lazy(() => import('../../Parts/Controls/factsList'));
// const FactsVirtualList =  React.lazy(() => import('../../Parts/Controls/factsVirtualList'));
// const FactsDropDown =  React.lazy(() => import( '../../Parts/Controls/factsDropDown'));
// const FactsFAB =   React.lazy(() => import( '../../Parts/Controls/factsFAB'));
// const FactsDataGrid = React.lazy(() => import('../../Parts/ControlsERP/factsDataGrid'));
 
// const FactsExpansionPanel =React.lazy(() => import( '../../Parts/Controls/factsExpansionPanel'));
// const FactsSwipeList = React.lazy(() => import('../../Parts/Controls/factsSwipeList'));
// const FactsButton =React.lazy(() => import( '../../Parts/Controls/factsButton'));
// const FactsEntry = React.lazy(() => import('../../Parts/Controls/factsEntry'));                                     
// const FactsLabel  = React.lazy(() => import( '../../Parts/Controls/factsLabel'));
// const FactsIconButton  = React.lazy(() => import( '../../Parts/Controls/factsIconButton'));
// const FactsDatePicker  = React.lazy(() => import( '../../Parts/Controls/factsDatePicker'));
// const FactsLookup  = React.lazy(() => import( '../../Parts/Controls/factsLookup'));
// const FactsTimePicker  = React.lazy(() => import( '../../Parts/Controls/factsTimePicker'));
// const FactsDevList  = React.lazy(() => import( '../../Parts/Controls/factsDevList'));
// const FactsClickRecognizer  = React.lazy(() => import( '../../Parts/Controls/factsClickRecognizer'));
// const FactsFileButton  = React.lazy(() => import( '../../Parts/Controls/factsFileButton'));
// const FactsImage  = React.lazy(() => import( '../../Parts/Controls/factsImage'));
// const FactsAvatar  = React.lazy(() => import( '../../Parts/Controls/factsAvatar'));
// const FactsHidden  = React.lazy(() => import( '../../Parts/Controls/factsHidden'));
// const FactsERPChart = React.lazy(() => import( '../../Parts/ControlsERP/factsERPChart'));
// const FactsCanvas = React.lazy(() =>  import('../../Parts/Controls/factsCanvas'));
// const FactsExtraContent = React.lazy(() =>  import('../../Parts/Controls/factsExtraContent'));
// const FactsRenderer = React.lazy(() => import( '../../Parts/Controls/factsRenderer'));
// const FactsCheckbox = React.lazy(() =>  import('../../Parts/Controls/factsCheckbox'));
// const FactsFABMulti = React.lazy(() =>  import('../../Parts/Controls/factsFABMulti'));
// const FactsSection = React.lazy(() =>  import('../../Parts/Controls/factsSection'));
// const FactsTreeview = React.lazy(() =>  import('../../Parts/Controls/factsTreeview'));
// const FactsQRCode = React.lazy(() =>  import('../../Parts/Controls/factsQRCode'));
// const FactsBarCode = React.lazy(() =>  import('../../Parts/Controls/factsBarCode'));

class PureJSComponentFactory {

    scaffoldComponent(layout, refData = null) {
        let layoutArray = layout;
        if (typeof layout === "string") layoutArray = GetControlPropertyFromStoreOrRefData(layout, refData);

        if (layoutArray && Array.isArray(layoutArray)) {
            return (
                layoutArray.map(
                    (ctrl, index) => {
                        let ctrlChildren = null;
                        if (ctrl.chld) {

                            if (Array.isArray(ctrl.chld)) {
                                ctrlChildren = this.scaffoldComponent(ctrl.chld, refData);
                            }
                            else if (typeof ctrl.chld === "string") {
                                ctrlChildren = GetControlPropertyFromStoreOrRefData(ctrl.chld, refData);

                                if (Array.isArray(ctrlChildren)) {
                                    ctrlChildren = this.scaffoldComponent(ctrlChildren, refData);
                                }
                            }
                        }
                        let controlType = this._getControlTypeFromKey(ctrl.type);

                        let resolvedProps = {};
                        if (ctrl.props && ctrl.props?.resolveprops) {
                            resolvedProps = { "resolveprops": undefined, ...getProcessedArgs(ctrl.props, refData, undefined, true), "whenclick": ctrl.props.whenclick };
                        }

                        let styleProps = {};
                        if (ctrl.props?.style && ctrl.props?.resolvestyles) {
                            styleProps = { "resolvestyles": undefined, style: getProcessedArgs(ctrl.props.style, refData) };
                        }
                        // let ctrlProps = ctrl.props ? { key: index, ...ctrl.props, ...styleProps } : { key: index, ...styleProps };
                        let ctrlProps = { key: index, ...ctrl.props, ...resolvedProps, ...styleProps };
                        if (ctrlProps.includerefdata) ctrlProps = { refData: refData, ...ctrlProps, "includerefdata": undefined };
                        return (React.createElement(controlType, ctrlProps, ctrlChildren)); 
                    }
                )
            );
        }
    }

    // _resolvestyles(styles, refData) { //TODO Need to refactor; Now using getProcessedArgs
    //     let newStyles = {};
    //     Object.keys(styles).forEach(t => {
    //         newStyles = {
    //             ...newStyles,
    //             [t]: GetControlPropertyFromStoreOrRefData(styles[t], refData)
    //         };

    //     });

    //     return newStyles;
    // }

    _getControlTypeFromKey(elementKey) {
        switch (elementKey.toLowerCase()) {
            //Charts
            case "echart": return (FactsERPChart);
            case "entry": return (FactsEntry);
            case "chbox": return (FactsCheckbox);
            case "dtpick": return (FactsDatePicker);
            case "tmpick": return (FactsTimePicker);
            case "lbl": return (FactsLabel);
            case "imgr": return (FactsImage);
            case "canv": return (FactsCanvas);
            // case "pdf": return (FactsPDFPreview);
            case "sect": return (FactsSection);
            case "ftbl": return (FactsDataGrid);
            case "expanel": return (FactsExpansionPanel);
            case "swplist": return (FactsSwipeList);
            case "btngroup": return (ButtonGroup);
            case "hide": return (FactsHidden);
            case "btn": return (FactsButton);
            case "filebtn": return (FactsFileButton);
            case "icbtn": return (FactsIconButton);
            case "fab": return (FactsFAB);
            case "mfab": return (FactsFABMulti);
            case "icon": return (Icon);
            case "ddl": return (FactsDropDown);
            case "lkup": return (FactsLookup);
            case "dxlist": return (FactsDevList);
            case "vlist": return (FactsVirtualList);
            case "list": return (FactsList);
            case "click": return (FactsClickRecognizer);
            case "favatar": return (FactsAvatar);
            case 'xcnt': return (FactsExtraContent);
            case "render": return (FactsRenderer);
            case "tree": return (FactsTreeview);
            case "qrscan": return (FactsQRCode);
            case "bcscan": return (FactsBarCode);
            case "dxsched": return (FactsDevScheduler);
            case "view": return (FactsHTMLView);
            case "draw": return (FactsDraw);

            default: return elementKey;
        }

    }

}

export default PureJSComponentFactory; 