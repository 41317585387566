import React, { Component } from "react";
import { ChangePageDataSetState, getProcessedArgs, objectMatchAll } from "../../../General/commonFunctions";
import store from "../../../AppRedux/store";
import ListViewBubbleTemplate from "../ControlTemplates/ListViewItem/listViewBubbleTemplate";
import ListViewCustomTemplate from "../ControlTemplates/ListViewItem/listViewCustomTemplate";
import { List } from "devextreme-react";

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

class FactsDevList extends Component {


    constructor(props) {
        super(props);
        this.state = {
            currentDS: this.filterDataset(this.getCurrentDataSet())
        };
        this.divRef = React.createRef();
    }

    ripOffControlSpecificAttributes() {
        this.scrolldown = this.props.scrolldown;

        const excluded = ["scrolldown", "content", "datasets", "refData", "filterto", "reverse"];
        return (Object.keys(this.props)
            .filter((t) => !excluded.includes(t))
            .reduce((obj, key) => {
                obj[key] = this.props[key];
                return obj;
            }, {}));
    }

    getCurrentDataSet() {
        let listds = ChangePageDataSetState(this.props.datasets);
        if(this.props.reverse && Array.isArray(listds)) listds = listds.reverse(); 

        return listds[this.props.datasets[0]];
    }

    filterDataset(listData) {
        if (this.props.filterto && listData) {
            let filterTo = getProcessedArgs(this.props.filterto, this.props.refData);
            if (filterTo) {
                return listData.filter(it => {

                    return objectMatchAll(it, filterTo);
                });
            }
            else return listData;
        }
        else return listData;
    }

    componentDidMount() {
        this.mounted = true;

        if (this.props.datasets && this.props.datasets.length > 0) {
            this.setState({
                currentDS: this.getCurrentDataSet()
            });
            // this.scrollToDown();

            this.unsubscribe = store.subscribe(() => {
                if (this.mounted) {
                    let newDS = this.getCurrentDataSet();
                    if (JSON.stringify(newDS) !== JSON.stringify(this.currentDS)) {
                        this.setState({
                            currentDS: this.filterDataset(newDS)
                        });
                        this.scrollToDown();
                    }
                }
            });
        }

    }

    scrollToDown() {
        if (this.scrolldown === true) {
            setTimeout(t => {
                if (this.divRef?.current?.lastChild) {
                    this.divRef.current.lastChild.scrollIntoView({ block: 'start' });
                }
                else this.scrollToDown();
            }, 25);
        }
    }

    componentWillUnmount() {
        this.mounted = false;
        this.scrolldown = false;
        if (this.unsubscribe) this.unsubscribe();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (JSON.stringify(this.state) === JSON.stringify(nextState)) {
            return false;
        } else {
            return true;
        }
    }

    render() {
        let newProps = this.ripOffControlSpecificAttributes();
        let listArrayToRender = this.convertToArrayIfObject(this.state.currentDS);
        let  index = 0; 
        return (
            <List
                dataSource={listArrayToRender}
                 // height={400}
                itemRender={(it) => {
                     let templ =  this.getContentTemplate(this.props.content, 
                    {
                        propkey: it.propkey ?? index,
                        "proptype": this.getValueType(it),
                        ...it
                    }, index); 
                    index++; 
                    return templ; 
                }
            }
                {...newProps} />
        );
    }

    convertToArrayIfObject(currentList) {
        if (currentList && !Array.isArray(currentList)) {
            return Object.keys(this.state.currentDS).map((t) => {
                return { "propkey": t, "propval": currentList[t], "proptype": this.getValueType(currentList[t]) }
            })
        }
        return currentList;

    }

    getValueType(val) {
        if (val === null) return "null";
        if (typeof val === "string") return "string";
        if (typeof val === "boolean") return "boolean";
        if (typeof val === "object") {
            if (Array.isArray(val)) return "array";
            else return "object";
        }
    }

    getContentTemplate(content, item, index) {
        switch (content.template) {
            case "lvibubble":
                return (<ListViewBubbleTemplate key={index} {...content.props} itemObject={item} />);

            case "custom":
                return (<ListViewCustomTemplate key={index} itemProps={content.props} layout={content.chld} itemObject={item} />);
            default: return (<div {...content.props} />);
        }
    }

}

export default FactsDevList; 