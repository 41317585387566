import React, { Component } from "react";
import { DatePicker } from '@material-ui/pickers/DatePicker';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
import { ExecuteLayoutEventMethods, GetControlPropertyFromStoreOrRefData } from "../../../General/commonFunctions";
import DateFnsUtils from "@date-io/date-fns";
import store from "../../../AppRedux/store";

class FactsDatePicker extends Component {
    constructor(props) {
        super(props);
        let dateValueFromState = GetControlPropertyFromStoreOrRefData("[" + props.dset + "." + props.bind + "]");
        this.state = {
            dateValue: (dateValueFromState === null ? "" : dateValueFromState)
        }
        this.finalValue = dateValueFromState;

    }
    
    componentDidMount() {
        this.mounted = true;
        this.unsubscribe = store.subscribe(() => {
            if (this.mounted) {

                let newState = GetControlPropertyFromStoreOrRefData("[" + this.props.dset + "." + this.props.bind + "]", this.props.refData);
                if (this.finalValue !== newState) {

                    if (newState === null) newState = "";
                    this.finalValue = newState;

                    this.setState({
                        dateValue: newState
                    });

                }
            }
        });

    }



    componentWillUnmount() {
        this.mounted = false;
        if (this.unsubscribe) this.unsubscribe();

    }

    async handleOnChange(date) {
        this.setState({
            dateValue: date
        }, async () => {
            await this.changeBinding()
            await ExecuteLayoutEventMethods(this.props.whenchange);
        });

    }
    async changeBinding() {
        if (this.props.dset && this.props.bind) {
            await ExecuteLayoutEventMethods([
                {
                    exec: "setdatasetfield",
                    args: {
                        dset: this.props.dset,
                        fieldname: this.props.bind,
                        data: this.state.dateValue
                    }
                }
            ]);
        }
    }
    render() {
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker {...this.props} value={this.state.dateValue} onChange={(date) => this.handleOnChange(date)} />
            </MuiPickersUtilsProvider>
        );
    }
}
export default FactsDatePicker;