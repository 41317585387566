import ServiceBase from "./_serviceBase";
import StorageService from "./storageService";
import { authStorageKey } from "../General/globals";


class PageService extends ServiceBase {

    constructor(authBody) {
        super();
        this.authBody = authBody;
    }

    async loadData(procKey, action_args, resultType) {
        let currentToken = new StorageService().getJsonValue(authStorageKey);
        if (!currentToken) return null;
        return await this.loadFromServer(this.authBody.job_id, procKey, currentToken.at, { info: action_args }, resultType);

    }

    async uploadFile(fileData) { //Untested; To contact the file processing end of API; Maybe the loadData function is enough
        let currentToken = new StorageService().getJsonValue(authStorageKey);
        if (!currentToken) return null;
        return await this.sendFileToServer(this.authBody.job_id, currentToken.at, { info: fileData });

    }
}

export default PageService;