import React, { Component } from 'react';
import UnAuthorizedComponent from './Components/unAuthorizedComponent';
import AuthorizedComponent from './Components/authorizedComponent';
import { authServiceInstance, applicationTitle, appTheme, appTimerExecs, objVoiceRecorder } from './General/globals';
import { Backdrop, CircularProgress, ThemeProvider, createMuiTheme } from '@material-ui/core';
import { HashRouter as Router, Switch as RouteSwitch, Route } from 'react-router-dom';
import store from './AppRedux/store';
import { authLogout, authLogin } from './AppRedux';
import { ExecuteLayoutEventMethods } from './General/commonFunctions';
import './Styles/basic.css';
import './Styles/responsive.css';
import { GPSLocation, DownloadFileFromState, } from './General/globalFunctions';
import AppDialog from './Components/Parts/AppControls/appDialog';
import AppSnackBar from './Components/Parts/AppControls/appSnackBar';
import AppMenu from './Components/Parts/AppControls/appMenu';
import appNotification from './Components/Parts/AppControls/appNotification';
 
class App extends Component {
  constructor() {
    super();

    this.state = {
      isPageLoading: true,
      isAuthenticated: false,
      isAppBusy: false,
      pageInfo: null,
      popupInfo: null,
      snackInfo: { open: false },
      dialogInfo: { open: false },
      menuInfo: { open: false }
    };

    this.pageTimerExecs = null;
    this.popupTimerExecs = null;

    GPSLocation();

    this.idleTimer = 0;
    this.idleReset(); //To track if the user is idle or not
    // this.saveGlobalAppState = this.saveGlobalAppState.bind(this);
    //test

    this.unsubscribe = store.subscribe((tt) => {
      let pageDs = store.getState();

      let loginDS = pageDs?.login;

      if (loginDS?.isAuthenticated !== this.state.isAuthenticated) {
        this.setState({
          isAuthenticated: loginDS?.isAuthenticated
        });
      }

      // if (loginDS?.isAppBusy !== this.state.isAppBusy) {
      this.setState({
        isAppBusy: loginDS?.isAppBusy
      });
      // }
      this.pageStateChange(pageDs?.pageinfo);

      this.popupStateChange(pageDs?.popupinfo);

      this.snackStateChange(pageDs?.snackinfo);

      this.dialogStateChange(pageDs?.dialoginfo);

      this.menuStateChange(pageDs?.menuinfo);

      appNotification(pageDs?.notifyinfo);

      pageDs?.mediainfo && objVoiceRecorder.doAction(pageDs?.mediainfo);

      if (pageDs?.downloadinfo?.open) DownloadFileFromState(pageDs?.downloadinfo); //Download base64 as file if any 

    });

    this.applyAppOptions();

  }

  idleReset() { //To reset idleTimer on mousemove; To be used later; Need to add it along with timer functions
    document.addEventListener("mousemove", () => this.idleTimer = 0);
  }

  async applyAppOptions() {
    if (appTheme) {
      this.theme = createMuiTheme(appTheme);
      if (appTheme?.header)
        document.querySelector("meta[name=theme-color]").setAttribute("content", appTheme?.header);
    }
  }

  async componentDidMount() {
    let authOK = await authServiceInstance.verifyLogin();

    // this.rootStateHandler = this.rootStateHandler.bind(this);
    store.dispatch(authOK ? authLogin() : authLogout()); //Redux

    this.setState({
      isPageLoading: false
    });
    this.runAppTimer();
  }

  componentWillUnmount() {
    this.unsubscribe();
    clearInterval(this.timeInterval);
  }

  pageStateChange(dsPageInfo) {
    // if (dsPageInfo) {
    let reduxPageInfoState = JSON.stringify(dsPageInfo),
      localPageInfoState = JSON.stringify(this.state.pageInfo);
    if (reduxPageInfoState !== localPageInfoState) {

      let layoutInfo = dsPageInfo;
      let pInfo = layoutInfo;

      document.title = `${applicationTitle} ${layoutInfo?.title ? " - " + layoutInfo?.title : ""}`;

      this.setState({
        pageInfo: pInfo
      },
        () => {
          ExecuteLayoutEventMethods(pInfo?.whenload); //Execute onload functions of Loaded Page
          this.pageTimerExecs = pInfo?.whentimer;
        });
    }
    // }
  }

  popupStateChange(dsPopupInfo) {
    if (dsPopupInfo) {
      let reduxPopupInfoState = JSON.stringify(dsPopupInfo),
        localPopupInfoState = JSON.stringify(this.state.popupInfo);
      if (reduxPopupInfoState !== localPopupInfoState) {

        // let popupLayoutInfo = pageDs?.popupinfo;
        let plInfo = dsPopupInfo;

        let needPopupEventExecution = [];
        // let currentURLHash = window.location.hash;
        // window.history.pushState({}, '')
        Object.keys(plInfo).forEach((pkey) => {
          let reduxPopupState = JSON.stringify(plInfo[pkey]),
            localPopupState = this.state.popupInfo && JSON.stringify(this.state.popupInfo[pkey]);
          if (reduxPopupState !== localPopupState) {

            // currentURLHash = currentURLHash + "/" + pkey;

            needPopupEventExecution.push(pkey);
          }
        });
        // window.location.hash = currentURLHash;

        this.setState({
          popupInfo: plInfo
        },
          () => {
            // window.history.pushState({}, '')
            needPopupEventExecution.forEach((pkey) => {
              ExecuteLayoutEventMethods(plInfo[pkey]?.whenload); //Execute onload functions of Loaded Popup
              this.popupTimerExecs = plInfo[pkey]?.whentimer;
            });
          });
      }
    }
  }

  snackStateChange(dsSnackInfo) {
    let reduxSnackInfoState = JSON.stringify(dsSnackInfo),
      localSnackInfoState = JSON.stringify(this.state.snackInfo);
    if (reduxSnackInfoState !== localSnackInfoState) {

      this.setState({
        snackInfo: dsSnackInfo
      })
    }
  }

  dialogStateChange(dsDialogInfo) {
    if (dsDialogInfo) {
      let reduxDialogInfoState = JSON.stringify(dsDialogInfo),
        localDialogInfoState = JSON.stringify(this.state.dialogInfo);
      if (reduxDialogInfoState !== localDialogInfoState) {

        this.setState({
          dialogInfo: dsDialogInfo
        })
      }
    }
  }

  menuStateChange(dsMenuInfo) {
    let reduxMenuInfoState = JSON.stringify(dsMenuInfo),
      localMenuInfoState = JSON.stringify(this.state.menuInfo);
    if (reduxMenuInfoState !== localMenuInfoState) {

      this.setState({
        menuInfo: dsMenuInfo
      })
    }
  }



  runAppTimer() {
    this.timerSeconds = 0;
    this.timeInterval = setInterval(() => {


      if (this.state.isAuthenticated) {
        this.executeTimerFunctions(appTimerExecs); //Execute timer functions of App; Need to pass it from UserDetails

        this.executeTimerFunctions(this.pageTimerExecs); //Execute timer functions of Loaded Page
        this.executeTimerFunctions(this.popupTimerExecs); //Execute timer functions of Loaded Popup
        this.timerSeconds++;
        this.idleTimer++;
      }

      if (this.idleTimer >= 86400) this.idleTimer = 0;  //Resetting idleTimer every 24 hours just to prevent overflow exception
      if (this.timerSeconds >= 86400) this.timerSeconds = 0;  //Resetting TimerSeconds every 24 hours just to prevent overflow exception

    }, 1000);


  }

  executeTimerFunctions(timerFunctions) {
    if (timerFunctions) {

      for (let tfn of timerFunctions) {
        if (this.timerSeconds % tfn?.interval === 0) {
          ExecuteLayoutEventMethods(tfn.tasks);
        }
      }
    }
  };




  // rootStateHandler(stateToMerge) {

  //   if (stateToMerge) {
  //     this.setState(stateToMerge, function () {

  //       // let jsonState = JSON.stringify(this.state); 
  //       // localStorage.setItem("as", jsonState); 
  //     });

  //   }
  // }


  _getComponentToStart(routerProps) {
    return (this.state.isAuthenticated ?
      <AuthorizedComponent pageInfo={this.state.pageInfo} popupInfo={this.state.popupInfo} {...routerProps} />
      :
      <UnAuthorizedComponent popupInfo={this.state.popupInfo} />
      );
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(this.state) === JSON.stringify(nextState)) {
      return false;
    } else {
      return true;
    }
  }

  render() {

    if (this.state.isPageLoading) return (
      <Backdrop open={this.state.isPageLoading} >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
      console.log(this.state.isAppBusy )
    // if ()
    return (
      <ThemeProvider theme={this.theme} >
        {this.state.isAppBusy ?
          (<Backdrop open={this.state.isAppBusy} style={{ zIndex: 9999 }} >
            <CircularProgress style={{ color: "#3f51b5" }} />
          </Backdrop>) : null}
        <Router>
          <RouteSwitch>
            <Route exact path="/" render={() => this._getComponentToStart(null)} ></Route>
            <Route path="/:permalink" render={routerProps => this._getComponentToStart(routerProps)} ></Route>
          </RouteSwitch>
        </Router>

        <AppSnackBar snackInfo={this.state.snackInfo} />

        <AppDialog dialogInfo={this.state.dialogInfo} />

        {this.state.menuInfo && this.state.menuInfo?.open &&
          <AppMenu menuInfo={this.state.menuInfo} />}

      </ThemeProvider>
    );

  }



}

export default App; 