import React, { Component } from "react";
import store from "../../../AppRedux/store";
import PureJSComponentFactory from "../../Pages/Factory/pureJSComponentFactory";
import { ExecuteLayoutEventMethods, GetControlPropertyFromStoreOrRefData } from "../../../General/commonFunctions";
import LayoutManipulator from "../../../General/layoutManipulator";

class FactsRenderer extends Component {
    constructor(props) {
        super(props);
        let newLY = this.getCurrentLayout();
        let neutralLY = new LayoutManipulator().neutralize(newLY, this.props.layout.replace("[", "").replace("]", ""));
        let scaff = new PureJSComponentFactory().scaffoldComponent(neutralLY, this.props.refData);
        this.state = {
            currentLY: newLY,
            scaff: scaff
        };
    }
    getCurrentLayout() {
        let layt = GetControlPropertyFromStoreOrRefData(this.props.layout);
        return layt;
    }

    componentDidMount() {
        document.querySelector(".c-render").onclick = async (e) => {
            document.querySelectorAll(".dz-ctr").forEach(t => {
                t.classList.remove("dz-active");
            });
            if (e.target.classList.contains("dz-ctr")) {
                e.target.classList.add("dz-active");
                let desProps = {
                    "path": e.target.getAttribute("data-ctr-path"),
                    "name": e.target.getAttribute("title")
                };

                await ExecuteLayoutEventMethods(this.props?.whenclick
                    //         [{
                    //         "exec": "setdataset",
                    //         "args": {
                    //             "dset": "despropinfo",
                    //             "data": {
                    //                 "path" : desPath,
                    //                 "name" : desName
                    //             }
                    //         }
                    //     }
                    // ]
                    , desProps);
            }


        };

        this.mounted = true;

        if (this.props.layout) {
            // this.setState({
            //     currentDS: this.getCurrentDataSet()
            // });
            this.unsubscribe = store.subscribe(() => {
                if (this.mounted) {

                    let newLY = this.getCurrentLayout();
                    let newLYString = JSON.stringify(newLY),
                        currentLYString = JSON.stringify(this.state.currentLY);

                    if (newLYString !== currentLYString) {
                        let neutralLY = new LayoutManipulator().neutralize(newLY, this.props.layout.replace("[", "").replace("]", ""));
                        let scaff = new PureJSComponentFactory().scaffoldComponent(neutralLY, this.props.refData);

                        this.setState({
                            currentLY: newLY,
                            scaff: scaff
                        });

                    }
                }
            });
        }

    }

    componentWillUnmount() {
        this.mounted = false;
        if (this.unsubscribe) this.unsubscribe();
    }


    render() {
        return (<div className="c-render">{this.state.scaff}</div>);
    }
}

export default FactsRenderer; 