import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import store from "../../../AppRedux/store";
import {  ExecuteLayoutEventMethods, getProcessedDynamic } from "../../../General/commonFunctions";

class FactsButton extends Component {
    constructor(props) {
        super(props);
        let _propsRedux = null;
        if (props.appendprops) _propsRedux = getProcessedDynamic(props.appendprops, this.props.refData);
        this.state = {
            propsRedux: _propsRedux
        }
        this.btnID = this.props.id ?? "btn" + this.getUniqueID();
    }

    getUniqueID() {
        return ([1e7] + 1e3 + 4e3 + 8e3 + 1e11).replace(/[018]/g, c =>
            (((c ^ crypto.getRandomValues(new Uint8Array(1))[0]) & 15) >> c / 4).toString(16)
        );
    }

    ripOffControlSpecificAttributes() {

        const excluded = ["whenclick", "appendprops",  "refData"];
        return (Object.keys(this.props)
            .filter((t) => !excluded.includes(t))
            .reduce((obj, key) => {
                obj[key] = this.props[key];
                return obj;
            }, {}));

    }

    componentDidMount() {
        this.mounted = true;
        if (this.props.appendprops) {
            this.unsubscribe = store.subscribe(() => {
                if (this.mounted) {
                    let newState = getProcessedDynamic(this.props.appendprops, this.props.refData);
                    if (JSON.stringify(this.state.propsRedux) !== JSON.stringify(newState)) {

                        this.setState({
                            propsRedux: newState
                        });
                    }
                }
            });
        }
    }

    componentWillUnmount() {
        this.mounted = false;
        if (this.unsubscribe) this.unsubscribe();
    }

    async handleButtonClick() {
        await ExecuteLayoutEventMethods(this.props.whenclick, { ...this.props.refData, controlid: this.btnID });

    }

    // shouldComponentUpdate(nextProps, nextState) {
    //     if (JSON.stringify(this.state) === JSON.stringify(nextState)) {
    //         return false;

    //     } else {
    //         return true;
    //     }
    // }

    render() {
        let newProps = this.ripOffControlSpecificAttributes();
        return (
            <Button id={this.btnID} type="submit" {...newProps} {...this.state.propsRedux} onClick={this.handleButtonClick.bind(this)}>{this.props.children}</Button>
        );
    }
}

export default FactsButton; 