import React, { Component } from "react";
import PureJSComponentFactory from "../../../Pages/Factory/pureJSComponentFactory";
import { ExecuteLayoutEventMethods } from "../../../../General/commonFunctions";


class ListViewCustomTemplate extends Component {
    constructor(props) {
        super(props);
        this.liRef = React.createRef();
    }
    async handleClick() {
        if (this.props.itemProps?.hlclass) {
            let litems = document.getElementsByClassName(this.props.itemProps?.hlclass);
            for (var i = 0; i < litems.length; i++) {
                litems[i].className = this.props.itemProps?.hlclass;
            }

            this.liRef.current.className = this.props.itemProps?.hlclass + ' list-active';

        }
        await ExecuteLayoutEventMethods(this.props.itemProps?.whenclick, this.props.itemObject);

    }

    render() {
        let scaff = new PureJSComponentFactory().scaffoldComponent(this.props.layout, this.props.itemObject);

        return (
            <div ref={this.liRef} {...this.props?.itemProps} whenclick={undefined}
                onClick={this.handleClick.bind(this)} >
                {scaff}
            </div>
        );
    }


}

export default ListViewCustomTemplate; 